import React from 'react';
import Layout from '../components/Layout/Layout';
import { Loading } from '../components/Loading/Loading';
import { Messages } from '../core/enums/messages';

export default function Index() {
	return <Layout>
		<Loading forcedMessage={Messages.LoadingCheckout} />
	</Layout>;
}
